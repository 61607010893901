// @@flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/campaign';

export const REQUEST_CAMPAIGNS = `${NAMESPACE}/request_campaigns`;
export const RECEIVE_CAMPAIGNS_SUCCESS = `${NAMESPACE}/receive_campaigns_success`;
export const RECEIVE_CAMPAIGNS_FAIL = `${NAMESPACE}/receive_campaigns_fail`;

const initialState = { data: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CAMPAIGNS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.campaigns || [],
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

const requestCampaigns = () => {
  return {
    type: REQUEST_CAMPAIGNS,
  };
};

const receiveCampaigns = (data) => {
  return {
    type: RECEIVE_CAMPAIGNS_SUCCESS,
    campaigns: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchCampaigns = () => {
  return (dispatch) => {
    dispatch(requestCampaigns());

    return axios
      .get(`${CONTENTFUL_PATH}campaign-items.json`)
      .then((resp) => dispatch(receiveCampaigns(resp.data)))
      .catch(console.error);
  };
};

export const fetchCampaigns = () => {
  return (dispatch) => dispatch(_fetchCampaigns());
};

export default reducer;
