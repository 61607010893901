// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/agreement-document';

export const REQUEST_AGREEMENT_DOCUMENTS = `${NAMESPACE}/request_agreement_documents`;
export const RECEIVE_AGREEMENT_DOCUMENTS_SUCCESS = `${NAMESPACE}/receive_agreement_documents_success`;
export const RECEIVE_AGREEMENT_DOCUMENTS_FAIL = `${NAMESPACE}/receive_agreement_documents_fail`;

const initialState = { data: [] };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_AGREEMENT_DOCUMENTS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_AGREEMENT_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.agreementDocuments,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

const requestAgreementDocuments = () => {
  return {
    type: REQUEST_AGREEMENT_DOCUMENTS,
  };
};

const receiveAgreementDocuments = (data) => {
  return {
    type: RECEIVE_AGREEMENT_DOCUMENTS_SUCCESS,
    agreementDocuments: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchAgreementDocuments = () => {
  return (dispatch: Function) => {
    dispatch(requestAgreementDocuments());

    return axios
      .get(`${CONTENTFUL_PATH}agreement-documents.json`)
      .then((resp) => dispatch(receiveAgreementDocuments(resp.data)))
      .catch(console.error);
  };
};

export const fetchAgreementDocuments = () => {
  return (dispatch: Function) => dispatch(_fetchAgreementDocuments());
};

export default reducer;
