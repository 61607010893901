// @flow
import {
  createStore as reduxCreateStore,
  compose,
  applyMiddleware,
  combineReducers,
} from 'redux';
import thunkMiddleware from 'redux-thunk';

import ui from './ui';
import agreementDocument from './agreement-document';
import merchant from './merchant';
import partner from './partner';
import premiumPartner from './premium-partner';
import meister from './meister';
import category from './category';
import campaign202109 from './campaign-202109';
import uiToBeCached from './ui-to-be-cached';
import consumerCampaign from './consumer-campaign';
import merchantCampaign from './merchant-campaign';
import editorsPick from './editors-pick';
import campaign from './campaign';
import caseStudy from './case-study';
import shopPageConfig from './shop-page-config';
import topPageConfig from './top-page-config';
import promotion from './promotion';
import companyInfo from './company-info';
import news from './news';
import history from './history';
import faq from './faq';
import consumerFaq from './consumer-faq';
import consumerFaqCategoriesOrder from './consumer-faq-categories-order';
import merchantFaq from './merchant-faq';
import merchantPdf from './merchant-pdf';
import merchantFaqCategoriesOrder from './merchant-faq-categories-order';
import crossSliceReducer from './cross';
import mediaCenter from './mediaCenter/reducer';
import merchantPages from './merchant/reducer';
import landing from './landing';

const combinedReducer = combineReducers({
  ui,
  uiToBeCached,
  agreementDocument,
  merchant,
  landing,
  partner,
  premiumPartner,
  meister,
  category,
  consumerCampaign,
  merchantCampaign,
  editorsPick,
  caseStudy,
  shopPageConfig,
  topPageConfig,
  promotion,
  companyInfo,
  news,
  history,
  faq,
  consumerFaq,
  consumerFaqCategoriesOrder,
  merchantFaq,
  merchantFaqCategoriesOrder,
  mediaCenter,
  merchantPages,
  merchantPdf,
  campaign,
  campaign202109,
});

function rootReducer(state, action) {
  const intermediateState = combinedReducer(state, action);
  const finalState = crossSliceReducer(intermediateState /* , action */);

  return finalState;
}

const windowExist = typeof window === 'object';

const createStore = () =>
  // $FlowFixMe
  reduxCreateStore(
    rootReducer,
    compose(
      applyMiddleware(thunkMiddleware),
      windowExist && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (x) => x
    )
  );

export default createStore;
