// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/case-study';

export const REQUEST_CASE_STUDIES = `${NAMESPACE}/request_case_studies`;
export const RECEIVE_CASE_STUDIES_SUCCESS = `${NAMESPACE}/receive_case_studies_success`;
export const RECEIVE_CASE_STUDIES_FAIL = `${NAMESPACE}/receive_case_studies_fail`;

const initialState = { data: [] };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_CASE_STUDIES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_CASE_STUDIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.caseStudies,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// consumer campaigns
const requestCaseStudies = () => {
  return {
    type: REQUEST_CASE_STUDIES,
  };
};

const receiveCaseStudies = (data) => {
  return {
    type: RECEIVE_CASE_STUDIES_SUCCESS,
    caseStudies: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchCaseStudies = () => {
  return (dispatch: Function) => {
    dispatch(requestCaseStudies());

    return axios
      .get(`${CONTENTFUL_PATH}case-studies.json`)
      .then((resp) => dispatch(receiveCaseStudies(resp.data)))
      .catch(console.error);
  };
};

export const fetchCaseStudies = () => {
  return (dispatch: Function) => dispatch(_fetchCaseStudies());
};

export default reducer;
