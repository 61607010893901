// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/faq';

export const REQUEST_FAQ = `${NAMESPACE}/request_faq`;
export const RECEIVE_FAQ_SUCCESS = `${NAMESPACE}/receive_faq_success`;
export const RECEIVE_FAQ_FAIL = `${NAMESPACE}/receive_faq_fail`;

const initialState = {};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_FAQ: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_FAQ_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.faq,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// consumer campaigns
const requestFAQ = () => {
  return {
    type: REQUEST_FAQ,
  };
};

const receiveFAQ = (data) => {
  return {
    type: RECEIVE_FAQ_SUCCESS,
    faq: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchFAQ = () => {
  return (dispatch: Function) => {
    dispatch(requestFAQ());

    return axios
      .get(`${CONTENTFUL_PATH}faq.json`)
      .then((resp) => dispatch(receiveFAQ(resp.data)))
      .catch(console.error);
  };
};

export const fetchFAQ = () => {
  return (dispatch: Function) => dispatch(_fetchFAQ());
};

export default reducer;
