// @@flow
const UPDATE_PAIRS = '@@checkout/ui_to_be_cached/update_pairs';

const initialState = {};

const uiToBeCached = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updatePairs = (pairs) => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default uiToBeCached;
