// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/landing';

export const REQUEST_LANDING = `${NAMESPACE}/request_landing`;
export const RECEIVE_LANDING_SUCCESS = `${NAMESPACE}/receive_landing_success`;
export const RECEIVE_LANDING_FAIL = `${NAMESPACE}/receive_landing_fail`;

const initialState = {};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_LANDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_LANDING_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.landing,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

const requestStaticLanding = () => {
  return {
    type: REQUEST_LANDING,
  };
};

const receiveStaticLanding = (data) => {
  return {
    type: RECEIVE_LANDING_SUCCESS,
    landing: data[0] || [],
    updatedAt: Date.now(),
  };
};

const _fetchStaticLanding = () => {
  return (dispatch: Function) => {
    dispatch(requestStaticLanding());

    return axios
      .get(`${CONTENTFUL_PATH}static-landing.json`)
      .then((resp) => dispatch(receiveStaticLanding(resp.data)))
      .catch(console.error);
  };
};

export const fetchStaticLanding = () => {
  return (dispatch: Function) => dispatch(_fetchStaticLanding());
};

export default reducer;
