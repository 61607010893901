// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/editors-pick';

export const REQUEST_EDITORS_PICKS = `${NAMESPACE}/request_editors_picks`;
export const RECEIVE_EDITORS_PICKS_SUCCESS = `${NAMESPACE}/receive_editors_picks_success`;
export const RECEIVE_EDITORS_PICKS_FAIL = `${NAMESPACE}/receive_editors_picks_fail`;

const initialState = { data: [] };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_EDITORS_PICKS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_EDITORS_PICKS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.editorsPicks,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// consumer campaigns
const requestEditorsPicks = () => {
  return {
    type: REQUEST_EDITORS_PICKS,
  };
};

const receiveEditorsPicks = (data) => {
  return {
    type: RECEIVE_EDITORS_PICKS_SUCCESS,
    editorsPicks: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchEditorsPicks = () => {
  return (dispatch: Function) => {
    dispatch(requestEditorsPicks());

    return axios
      .get(`${CONTENTFUL_PATH}editor-picks.json`)
      .then((resp) => dispatch(receiveEditorsPicks(resp.data)))
      .catch(console.error);
  };
};

export const fetchEditorsPicks = () => {
  return (dispatch: Function) => dispatch(_fetchEditorsPicks());
};

export default reducer;
