// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/merchant';

export const REQUEST_CATEGORIES = `${NAMESPACE}/request_categories`;
export const RECEIVE_CATEGORIES_SUCCESS = `${NAMESPACE}/receive_categories_success`;
export const RECEIVE_CATEGORIES_FAIL = `${NAMESPACE}/receive_categories_fail`;

const initialState = { data: {} };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_CATEGORIES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.categories,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// merchant list
const requestCategories = () => {
  return {
    type: REQUEST_CATEGORIES,
  };
};

const receiveCategories = (data) => {
  return {
    type: RECEIVE_CATEGORIES_SUCCESS,
    categories: data || {},
    updatedAt: Date.now(),
  };
};

const _fetchCategories = () => {
  return (dispatch: Function) => {
    dispatch(requestCategories());

    return axios
      .get(`${CONTENTFUL_PATH}categories.json`)
      .then((resp) => dispatch(receiveCategories(resp.data)))
      .catch(console.error);
  };
};

export const fetchCategories = () => {
  return (dispatch: Function) => dispatch(_fetchCategories());
};

export default reducer;
