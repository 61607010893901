const NAMESPACE = '@@paidy.com/media_center';

//Async
export const GET_TOP_PAGE_ASYNC = `${NAMESPACE}/GET_TOP_PAGE_ASYNC`;
export const GET_FANS_PAGE_ASYNC = `${NAMESPACE}/GET_FANS_PAGE_ASYNC`;
export const GET_PRESS_PAGE_ASYNC = `${NAMESPACE}/GET_PRESS_PAGE_ASYNC`;
export const GET_HR_PAGE_ASYNC = `${NAMESPACE}/GET_HR_PAGE_ASYNC`;
export const GET_WORK_LIFE_TOP_PAGE_ASYNC = `${NAMESPACE}/GET_WORK_LIFE_TOP_PAGE_ASYNC`;
export const GET_ENGINEERING_PAGE_ASYNC = `${NAMESPACE}/GET_ENGINEERING_PAGE_ASYNC`;
export const GET_FANS_ARTICLES_ASYNC = `${NAMESPACE}/GET_FANS_ARTICLES_ASYNC`;
export const GET_PRESS_ARTICLES_ASYNC = `${NAMESPACE}/GET_PRESS_ARTICLES_ASYNC`;
export const GET_ENGINEERING_ARTICLES_ASYNC = `${NAMESPACE}/GET_ENGINEERING_ARTICLES_ASYNC`;
export const GET_HR_ARTICLES_ASYNC = `${NAMESPACE}/GET_HR_ARTICLES_ASYNC`;
export const GET_RECOMMENDED_ARTICLES_ASYNC = `${NAMESPACE}/GET_RECOMMENDED_ARTICLES_ASYNC`;

//Sync
export const SET_TOP_PAGE = `${NAMESPACE}/SET_TOP_PAGE`;
export const SET_FANS_PAGE = `${NAMESPACE}/SET_FANS_PAGE`;
export const SET_PRESS_PAGE = `${NAMESPACE}/SET_PRESS_PAGE`;
export const SET_HR_PAGE = `${NAMESPACE}/SET_HR_PAGE`;
export const SET_WORK_LIFE_TOP_PAGE = `${NAMESPACE}/SET_WORK_LIFE_TOP_PAGE`;
export const SET_ENGINEERING_PAGE = `${NAMESPACE}/SET_ENGINEERING_PAGE`;
export const SET_FANS_ARTICLES = `${NAMESPACE}/SET_FANS_ARTICLES`;
export const SET_PRESS_ARTICLES = `${NAMESPACE}/SET_PRESS_ARTICLES`;
export const SET_ENGINEERING_ARTICLES = `${NAMESPACE}/SET_ENGINEERING_ARTICLES`;
export const SET_HR_ARTICLES = `${NAMESPACE}/SET_HR_ARTICLES`;
export const SET_RECOMMENDED_ARTICLES = `${NAMESPACE}/SET_RECOMMENDED_ARTICLES`;
