// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/partner';

export const REQUEST_PARTNERS = `${NAMESPACE}/request_partners`;
export const RECEIVE_PARTNERS_SUCCESS = `${NAMESPACE}/receive_partners_success`;
export const RECEIVE_PARTNERS_FAIL = `${NAMESPACE}/receive_partners_fail`;

const initialState = { data: [] };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_PARTNERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_PARTNERS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.partners,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// Partners list
const requestPartners = () => {
  return {
    type: REQUEST_PARTNERS,
  };
};

const receivePartners = (data) => {
  return {
    type: RECEIVE_PARTNERS_SUCCESS,
    partners: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchPartners = () => {
  return (dispatch: Function) => {
    dispatch(requestPartners());

    return axios
      .get(`${CONTENTFUL_PATH}partners.json`)
      .then((resp) => dispatch(receivePartners(resp.data)))
      .catch(console.error);
  };
};

export const fetchPartners = () => {
  return (dispatch: Function) => dispatch(_fetchPartners());
};

export default reducer;
