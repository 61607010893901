// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/consumer-campaign';

export const REQUEST_CONSUMER_CAMPAIGNS = `${NAMESPACE}/request_consumer_campaigns`;
export const RECEIVE_CONSUMER_CAMPAIGNS_SUCCESS = `${NAMESPACE}/receive_consumer_campaigns_success`;
export const RECEIVE_CONSUMER_CAMPAIGNS_FAIL = `${NAMESPACE}/receive_consumer_campaigns_fail`;

const initialState = { data: {} };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_CONSUMER_CAMPAIGNS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_CONSUMER_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.consumerCampaigns,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// consumer campaigns
const requestConsumerCampaigns = () => {
  return {
    type: REQUEST_CONSUMER_CAMPAIGNS,
  };
};

const receiveConsumerCampaigns = (data) => {
  return {
    type: RECEIVE_CONSUMER_CAMPAIGNS_SUCCESS,
    consumerCampaigns: data || {},
    updatedAt: Date.now(),
  };
};

const _fetchConsumerCampaigns = () => {
  return (dispatch: Function) => {
    dispatch(requestConsumerCampaigns());

    return axios
      .get(`${CONTENTFUL_PATH}campaigns.json`)
      .then((resp) => dispatch(receiveConsumerCampaigns(resp.data)))
      .catch(console.error);
  };
};

export const fetchConsumerCampaigns = () => {
  return (dispatch: Function) => dispatch(_fetchConsumerCampaigns());
};

export default reducer;
