// @flow
import axios from 'axios';
import groupBy from 'lodash.groupby';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/news';

export const REQUEST_NEWS = `${NAMESPACE}/request_news`;
export const RECEIVE_NEWS_SUCCESS = `${NAMESPACE}/receive_news_success`;
export const RECEIVE_NEWS_FAIL = `${NAMESPACE}/receive_news_fail`;

const initialState = {};

const groupNews = (news: Array<any>) => {
  const grouped = groupBy(news, 'category') || {};

  grouped.all = news;

  return grouped;
};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_NEWS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_NEWS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.news,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

const requestNews = () => {
  return {
    type: REQUEST_NEWS,
  };
};

const receiveNews = (data) => {
  return {
    type: RECEIVE_NEWS_SUCCESS,
    news: data || {},
    updatedAt: Date.now(),
  };
};

const _fetchNews = () => {
  return (dispatch: Function) => {
    dispatch(requestNews());

    return axios
      .get(`${CONTENTFUL_PATH}news.json`)
      .then((resp) => dispatch(receiveNews(groupNews(resp.data))))
      .catch(console.error);
  };
};

export const fetchNews = () => {
  return (dispatch: Function) => dispatch(_fetchNews());
};

export default reducer;
