// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

import { getRandomInt } from '../utils/merchant';

const NAMESPACE = '@@paidy.com/merchant';

export const REQUEST_MERCHANTS = `${NAMESPACE}/request_merchants`;
export const RECEIVE_MERCHANTS_SUCCESS = `${NAMESPACE}/receive_merchants_success`;
export const RECEIVE_MERCHANTS_FAIL = `${NAMESPACE}/receive_merchants_fail`;

const bgColors = ['#e5258c', '#823cd7', '#ffcd00', '#2196f3', '#8bc34a'];
const initialState = { data: [] };

export const normalizePlaceholderBG = (merchants: Array<any>) => {
  // $FlowFixMe
  return merchants.map((m) => {
    m.placeholderBGColor = bgColors[getRandomInt(5)];

    return m;
  });
};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_MERCHANTS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_MERCHANTS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.merchants,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// merchant list
const requestMerchants = () => {
  return {
    type: REQUEST_MERCHANTS,
  };
};

const receiveMerchants = (data) => {
  return {
    type: RECEIVE_MERCHANTS_SUCCESS,
    merchants: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchMerchants = () => {
  return (dispatch: Function) => {
    dispatch(requestMerchants());

    return axios
      .get(`${CONTENTFUL_PATH}merchants.json`)
      .then((resp) =>
        dispatch(receiveMerchants(normalizePlaceholderBG(resp.data)))
      )
      .catch(console.error);
  };
};

export const fetchMerchants = () => {
  return (dispatch: Function) => dispatch(_fetchMerchants());
};

export default reducer;
