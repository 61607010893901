// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/merchant-campaign';

export const REQUEST_MERCHANT_CAMPAIGNS = `${NAMESPACE}/request_merchant_campaigns`;
export const RECEIVE_MERCHANT_CAMPAIGNS_SUCCESS = `${NAMESPACE}/receive_merchant_campaigns_success`;
export const RECEIVE_MERCHANT_CAMPAIGNS_FAIL = `${NAMESPACE}/receive_merchant_campaigns_fail`;

const initialState = {};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_MERCHANT_CAMPAIGNS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_MERCHANT_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.merchantCampaigns,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// merchant campaigns
const requestMerchantCampaigns = () => {
  return {
    type: REQUEST_MERCHANT_CAMPAIGNS,
  };
};

const receiveMerchantCampaigns = (data) => {
  return {
    type: RECEIVE_MERCHANT_CAMPAIGNS_SUCCESS,
    merchantCampaigns: data || {},
    updatedAt: Date.now(),
  };
};

const _fetchMerchantCampaigns = () => {
  return (dispatch: Function) => {
    dispatch(requestMerchantCampaigns());

    return axios
      .get(`${CONTENTFUL_PATH}merchant-campaigns.json`)
      .then((resp) => dispatch(receiveMerchantCampaigns(resp.data)))
      .catch(console.error);
  };
};

export const fetchMerchantCampaigns = () => {
  return (dispatch: Function) => dispatch(_fetchMerchantCampaigns());
};

export default reducer;
