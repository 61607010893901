// @flow
import idx from 'idx';

export const catToDisplay = (
  catSlugs: Array<string>,
  cats: Object,
  currentCat?: string
) => {
  const currentCatTitle = idx(cats, (_) => _[currentCat].title);

  // when there's a category selected
  if (currentCatTitle) {
    return currentCatTitle;
  }

  // if none selected then pick the first cat we get and see if it has parent
  // if there's one we then use that to display in the search results
  const firstCatSlug = catSlugs[0];
  const parentCatSlug = idx(cats, (_) => _[firstCatSlug].parent);

  if (parentCatSlug && cats[parentCatSlug]) {
    return cats[parentCatSlug].title;
  }

  // if it's already a parent category, we will then use that to display
  return idx(cats, (_) => _[firstCatSlug].title);
};

export const popularitySort = (x: Object, y: Object) => {
  const xp = x.popularity || 100;
  const yp = y.popularity || 100;

  return xp - yp;
};

export const firstSectionSort = (x: Object, y: Object) => {
  const xp = x.orderInFirstSection || 100;
  const yp = y.orderInFirstSection || 100;

  return xp - yp;
};

export const secondSectionSort = (x: Object, y: Object) => {
  const xp = x.orderInSecondSection || 100;
  const yp = y.orderInSecondSection || 100;

  return xp - yp;
};

export const kanaSort = (x: Object, y: Object) => {
  if (x.shopNameKata < y.shopNameKata) {
    return -1;
  }

  if (x.shopNameKata > y.shopNameKata) {
    return 1;
  }

  return 0;
};

export const getRandomInt = (max: number) =>
  Math.floor(Math.random() * Math.floor(max));
