// @flow
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from "@material-ui/core/styles"

import theme from "./src/theme"

import createStore from './src/redux/create-store';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: { element: React$Node }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();

  return <ThemeProvider theme={theme}><Provider store={store}>{element}</Provider></ThemeProvider>
};
