// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/shop-page-config';

export const REQUEST_SHOP_PAGE_CONFIG = `${NAMESPACE}/request_shop_page_config`;
export const RECEIVE_SHOP_PAGE_CONFIG_SUCCESS = `${NAMESPACE}/receive_shop_page_config_success`;
export const RECEIVE_SHOP_PAGE_CONFIG_FAIL = `${NAMESPACE}/receive_shop_page_config_fail`;

const initialState = {};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_SHOP_PAGE_CONFIG: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_SHOP_PAGE_CONFIG_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.shopPageConfig,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// consumer campaigns
const requestShopPageConfig = () => {
  return {
    type: REQUEST_SHOP_PAGE_CONFIG,
  };
};

const receiveShopPageConfig = (data) => {
  return {
    type: RECEIVE_SHOP_PAGE_CONFIG_SUCCESS,
    shopPageConfig: data || {},
    updatedAt: Date.now(),
  };
};

const _fetchShopPageConfig = () => {
  return (dispatch: Function) => {
    dispatch(requestShopPageConfig());

    return axios
      .get(`${CONTENTFUL_PATH}shop-page-config.json`)
      .then((resp) => dispatch(receiveShopPageConfig(resp.data)))
      .catch(console.error);
  };
};

export const fetchShopPageConfig = () => {
  return (dispatch: Function) => dispatch(_fetchShopPageConfig());
};

export default reducer;
