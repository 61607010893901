// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/premium-partner';

export const REQUEST_PREMIUM_PARTNERS = `${NAMESPACE}/request_premium_partners`;
export const RECEIVE_PREMIUM_PARTNERS_SUCCESS = `${NAMESPACE}/receive_premium_partners_success`;
export const RECEIVE_PREMIUM_PARTNERS_FAIL = `${NAMESPACE}/receive_premium_partners_fail`;

const initialState = { data: [] };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_PREMIUM_PARTNERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_PREMIUM_PARTNERS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.premiumPartners,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

const requestPremiumPartners = () => {
  return {
    type: REQUEST_PREMIUM_PARTNERS,
  };
};

const receivePremiumPartners = (data) => {
  return {
    type: RECEIVE_PREMIUM_PARTNERS_SUCCESS,
    premiumPartners: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchPremiumPartners = () => {
  return (dispatch: Function) => {
    dispatch(requestPremiumPartners());

    return axios
      .get(`${CONTENTFUL_PATH}premium-partners.json`)
      .then((resp) => dispatch(receivePremiumPartners(resp.data)))
      .catch(console.error);
  };
};

export const fetchPremiumPartners = () => {
  return (dispatch: Function) => dispatch(_fetchPremiumPartners());
};

export default reducer;
