// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/merchant_pdf';

export const REQUEST_PDF = `${NAMESPACE}/request_pdf`;
export const RECEIVE_PDF_SUCCESS = `${NAMESPACE}/receive_pdf_success`;
export const RECEIVE_PDF_FAIL = `${NAMESPACE}/receive_pdf_fail`;

const initialState = {};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_PDF: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_PDF_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.merchantPdf,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// merchant campaigns
const requestMerchantPDF = () => {
  return {
    type: REQUEST_PDF,
  };
};

const receiveMerchantPDF = (data) => {
  return {
    type: RECEIVE_PDF_SUCCESS,
    merchantPdf: data[0] || [],
    updatedAt: Date.now(),
  };
};

const _fetchMerchantPDF = () => {
  return (dispatch: Function) => {
    dispatch(requestMerchantPDF());

    return axios
      .get(`${CONTENTFUL_PATH}merchant-pdf.json`)
      .then((resp) => dispatch(receiveMerchantPDF(resp.data)))
      .catch(console.error);
  };
};

export const fetchMerchantPDF = () => {
  return (dispatch: Function) => dispatch(_fetchMerchantPDF());
};

export default reducer;
