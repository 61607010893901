// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/meister';

export const REQUEST_MEISTERS = `${NAMESPACE}/request_meisters`;
export const RECEIVE_MEISTERS_SUCCESS = `${NAMESPACE}/receive_meisters_success`;
export const RECEIVE_MEISTERS_FAIL = `${NAMESPACE}/receive_meisters_fail`;

const initialState = { data: [] };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_MEISTERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_MEISTERS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.meisters,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

const requestMeisters = () => {
  return {
    type: REQUEST_MEISTERS,
  };
};

const receiveMeisters = (data) => {
  return {
    type: RECEIVE_MEISTERS_SUCCESS,
    meisters: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchMeisters = () => {
  return (dispatch: Function) => {
    dispatch(requestMeisters());

    return axios
      .get(`${CONTENTFUL_PATH}meisters.json`)
      .then((resp) => dispatch(receiveMeisters(resp.data)))
      .catch(console.error);
  };
};

export const fetchMeisters = () => {
  return (dispatch: Function) => dispatch(_fetchMeisters());
};

export default reducer;
