// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/company-info';

export const REQUEST_COMPANY_INFO = `${NAMESPACE}/request_company_info`;
export const RECEIVE_COMPANY_INFO_SUCCESS = `${NAMESPACE}/receive_company_info_success`;
export const RECEIVE_COMPANY_INFO_FAIL = `${NAMESPACE}/receive_company_info_fail`;

const initialState = { data: {} };

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_COMPANY_INFO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.companyInfo,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

const requestCompanyInfo = () => {
  return {
    type: REQUEST_COMPANY_INFO,
  };
};

const receiveCompanyInfo = (data) => {
  return {
    type: RECEIVE_COMPANY_INFO_SUCCESS,
    companyInfo: data || {},
    updatedAt: Date.now(),
  };
};

const _fetchCompanyInfo = () => {
  return (dispatch: Function) => {
    dispatch(requestCompanyInfo());

    return axios
      .get(`${CONTENTFUL_PATH}company-info.json`)
      .then((resp) => dispatch(receiveCompanyInfo(resp.data)))
      .catch(console.error);
  };
};

export const fetchCompanyInfo = () => {
  return (dispatch: Function) => dispatch(_fetchCompanyInfo());
};

export default reducer;
