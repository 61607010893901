// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/merchant_faq_categories_order';

export const REQUEST_FAQ = `${NAMESPACE}/request_faq`;
export const RECEIVE_FAQ_SUCCESS = `${NAMESPACE}/receive_faq_success`;
export const RECEIVE_FAQ_FAIL = `${NAMESPACE}/receive_faq_fail`;

const initialState = {};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_FAQ: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_FAQ_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.merchantFaqCategoriesOrder,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// merchant campaigns
const requestMerchantFAQCategoriesOrder = () => {
  return {
    type: REQUEST_FAQ,
  };
};

const receiveMerchantFAQCategoriesOrder = (data) => {
  return {
    type: RECEIVE_FAQ_SUCCESS,
    merchantFaqCategoriesOrder: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchMerchantFAQCategoriesOrder = () => {
  return (dispatch: Function) => {
    dispatch(requestMerchantFAQCategoriesOrder());

    return axios
      .get(`${CONTENTFUL_PATH}merchant-faq-categories-order.json`)
      .then((resp) => dispatch(receiveMerchantFAQCategoriesOrder(resp.data)))
      .catch(console.error);
  };
};

export const fetchMerchantFAQCategoriesOrder = () => {
  return (dispatch: Function) => dispatch(_fetchMerchantFAQCategoriesOrder());
};

export default reducer;
