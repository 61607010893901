//Local
import {
  GET_TOP_PAGE_ASYNC,
  GET_PRESS_ARTICLES_ASYNC,
  SET_TOP_PAGE,
  SET_FANS_PAGE,
  SET_PRESS_PAGE,
  SET_HR_PAGE,
  SET_WORK_LIFE_TOP_PAGE,
  SET_ENGINEERING_PAGE,
  SET_FANS_ARTICLES,
  SET_PRESS_ARTICLES,
  SET_ENGINEERING_ARTICLES,
  SET_HR_ARTICLES,
  SET_RECOMMENDED_ARTICLES,
} from './types';

const initialState = {
  topPage: { data: {}, isFetching: true },
  fansPage: {},
  pressPage: {},
  workLifeTopPage: {},
  hrPage: {},
  engineeringPage: {},
  fansArticles: [],
  pressArticles: { data: [], isFetching: true },
  hrArticles: [],
  engineeringArticles: [],
  recommendedArticles: [],
};

const mediaCenterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TOP_PAGE_ASYNC:
      return { ...state, topPage: { ...state.topPage, isFetching: true } };

    case SET_TOP_PAGE:
      return {
        ...state,
        topPage: { ...state.topPage, data: payload, isFetching: false },
      };

    case SET_FANS_PAGE:
      return { ...state, fansPage: payload };

    case SET_PRESS_PAGE:
      return { ...state, pressPage: payload };

    case SET_WORK_LIFE_TOP_PAGE:
      return { ...state, workLifeTopPage: payload };

    case SET_ENGINEERING_PAGE:
      return { ...state, engineeringPage: payload };

    case SET_HR_PAGE:
      return { ...state, hrPage: payload };

    case SET_FANS_ARTICLES:
      return { ...state, fansArticles: payload };

    case GET_PRESS_ARTICLES_ASYNC:
      return {
        ...state,
        pressArticles: { ...state.pressArticles, isFetching: true },
      };

    case SET_PRESS_ARTICLES:
      return {
        ...state,
        pressArticles: {
          ...state.pressArticles,
          data: payload,
          isFetching: false,
        },
      };

    case SET_ENGINEERING_ARTICLES:
      return { ...state, engineeringArticles: payload };

    case SET_HR_ARTICLES:
      return { ...state, hrArticles: payload };

    case SET_RECOMMENDED_ARTICLES:
      return { ...state, recommendedArticles: payload };

    default:
      return state;
  }
};

export default mediaCenterReducer;
