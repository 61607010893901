// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/consumer_faq';

export const REQUEST_FAQ = `${NAMESPACE}/request_faq`;
export const RECEIVE_FAQ_SUCCESS = `${NAMESPACE}/receive_faq_success`;
export const RECEIVE_FAQ_FAIL = `${NAMESPACE}/receive_faq_fail`;

const initialState = {};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_FAQ: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_FAQ_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.consumerFaq,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// consumer campaigns
const requestConsumerFAQ = () => {
  return {
    type: REQUEST_FAQ,
  };
};

const receiveConsumerFAQ = (data) => {
  return {
    type: RECEIVE_FAQ_SUCCESS,
    consumerFaq: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchConsumerFAQ = () => {
  return (dispatch: Function) => {
    dispatch(requestConsumerFAQ());

    return axios
      .get(`${CONTENTFUL_PATH}consumer-faq.json`)
      .then((resp) => dispatch(receiveConsumerFAQ(resp.data)))
      .catch(console.error);
  };
};

export const fetchConsumerFAQ = () => {
  return (dispatch: Function) => dispatch(_fetchConsumerFAQ());
};

export default reducer;
