// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/top-page-config';

export const REQUEST_TOP_PAGE_CONFIG = `${NAMESPACE}/request_top_page_config`;
export const RECEIVE_TOP_PAGE_CONFIG_SUCCESS = `${NAMESPACE}/receive_top_page_config_success`;
export const RECEIVE_TOP_PAGE_CONFIG_FAIL = `${NAMESPACE}/receive_top_page_config_fail`;

const initialState = {};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_TOP_PAGE_CONFIG: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_TOP_PAGE_CONFIG_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.topPageConfig,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// consumer campaigns
const requestTopPageConfig = () => {
  return {
    type: REQUEST_TOP_PAGE_CONFIG,
  };
};

const receiveTopPageConfig = (data) => {
  return {
    type: RECEIVE_TOP_PAGE_CONFIG_SUCCESS,
    topPageConfig: data || {},
    updatedAt: Date.now(),
  };
};

const _fetchTopPageConfig = () => {
  return (dispatch: Function) => {
    dispatch(requestTopPageConfig());

    return axios
      .get(`${CONTENTFUL_PATH}top-page-config.json`)
      .then((resp) => dispatch(receiveTopPageConfig(resp.data)))
      .catch(console.error);
  };
};

export const fetchTopPageConfig = () => {
  return (dispatch: Function) => dispatch(_fetchTopPageConfig());
};

export default reducer;
