// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/history';

export const REQUEST_HISTORY = `${NAMESPACE}/request_history`;
export const RECEIVE_HISTORY_SUCCESS = `${NAMESPACE}/receive_history_success`;
export const RECEIVE_HISTORY_FAIL = `${NAMESPACE}/receive_history_fail`;

const initialState = {};

const groupHistory = (history: Array<any>) => {
  const grouped = {};

  history.forEach((item) => {
    const dateKey = item.date.substring(0, 4);
    const month = +item.date.substring(5, 7);

    if (!grouped[dateKey]) {
      grouped[dateKey] = [{ ...item, month }];
    } else {
      grouped[dateKey].push({ ...item, month });
    }
  });

  return grouped;
};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_HISTORY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_HISTORY_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.history,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

const requestHistory = () => {
  return {
    type: REQUEST_HISTORY,
  };
};

const receiveHistory = (data) => {
  return {
    type: RECEIVE_HISTORY_SUCCESS,
    history: data || [],
    updatedAt: Date.now(),
  };
};

const _fetchHistory = () => {
  return (dispatch: Function) => {
    dispatch(requestHistory());

    return axios
      .get(`${CONTENTFUL_PATH}company-history.json`)
      .then((resp) => dispatch(receiveHistory(groupHistory(resp.data))))
      .catch(console.error);
  };
};

export const fetchHistory = () => {
  return (dispatch: Function) => dispatch(_fetchHistory());
};

export default reducer;
