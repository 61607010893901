// @flow
import axios from 'axios';
import { CONTENTFUL_PATH } from '../constants';

const NAMESPACE = '@@paidy.com/promotion';

export const REQUEST_PROMOTION = `${NAMESPACE}/request_promotion`;
export const RECEIVE_PROMOTION_SUCCESS = `${NAMESPACE}/receive_promotion_success`;
export const RECEIVE_PROMOTION_FAIL = `${NAMESPACE}/receive_promotion_fail`;

const initialState = { data: {} };

export const singelizePromotion = (promotions: Array<any>) => {
  const theone = promotions[0];

  if (theone && theone.merchants) {
    theone.merchant = theone.merchants[0];

    delete theone.merchats;
  }

  return theone;
};

const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case REQUEST_PROMOTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RECEIVE_PROMOTION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.promotion,
        updatedAt: action.updatedAt,
      };
    }
    default: {
      return state;
    }
  }
};

// consumer campaigns
const requestPromotion = () => {
  return {
    type: REQUEST_PROMOTION,
  };
};

const receivePromotion = (data) => {
  return {
    type: RECEIVE_PROMOTION_SUCCESS,
    promotion: data || {},
    updatedAt: Date.now(),
  };
};

const _fetchPromotion = () => {
  return (dispatch: Function) => {
    dispatch(requestPromotion());

    return axios
      .get(`${CONTENTFUL_PATH}promotions.json`)
      .then((resp) => dispatch(receivePromotion(singelizePromotion(resp.data))))
      .catch(console.error);
  };
};

export const fetchPromotion = () => {
  return (dispatch: Function) => dispatch(_fetchPromotion());
};

export default reducer;
