// @@flow
const UPDATE_PAIRS = '@@checkout/ui/update_pairs';

const initialState = {
  isLoading: false,
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updatePairs = (pairs) => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default ui;
